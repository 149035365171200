<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex"> -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-flex">
      <div class="beta-version ml-05 ml-lg-0" v-b-tooltip.hover.right="'My Mental Health Check Beta!'">Beta</div>
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <notification-dropdown />

      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{userData.name}}
            </p>
            <span class="user-status">{{ userData.username }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge
            :src="userData.avatar"
            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" :to="{name:'profile'}">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click="logout">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import store from '@/store/index'
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar, VBTooltip
} from 'bootstrap-vue'
import NotificationDropdown from './NotificationDropdown.vue'

//import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar components
    NotificationDropdown,

  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    userData() {
      return store.state.app.userData;
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mounted() {
    let username = store.state.app.userData.username;
    if(username == null || username == "" || username == undefined) {
      this.show_username_modal = true;
    }
  },
  data() {
    return {
      show_username_modal: false
    };
  },
  methods: {
    profile() {
      this.$router.push("/profile")
    },
    logout() {            
      const userData = store.state.app.userData;
      const token = `${userData.token_type} ${userData.accessToken}`;

      const requestOptions = {
        headers: {
          "Content-Type": "application/json",
          Authorization: token,
        },
      };
      this.$http.get('/api/logout', requestOptions)
      .then(response => {        
        store.commit('app/removeUserData')
        localStorage.removeItem('userData');
      })
      .catch(error => {
        console.log(error)
        store.commit('app/removeUserData')
        localStorage.removeItem('userData');
      })
      store.commit('app/removeUserData')
      localStorage.removeItem('userData');
      this.$router.push("/login")
    }
  },
}
</script>

<style scoped>
  .beta-version {
    padding: 8px 15px;
    border-radius: 10px;
    background-color: #245bf0;
    font-weight: 500;
    color: #fff;
  }
</style>