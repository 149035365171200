export default [
  {
    title: 'Questionnaires',
    route: 'home',
    icon: 'FileTextIcon',
    permissions: ["Manage Questionnaires", "Add Questionnaires", "Can Give Support"],
    children: [
      {
        title: 'Feed',
        route: 'questionnaire-feed',
        permission: "Can Give Support"
      },
      {
        title: 'Manage',
        route: 'home',
        permission: "Manage Questionnaires"
      },
      {
        title: 'Add new',
        route: 'questionnaire-create',
        permission: "Add Questionnaires"
      },
    ]
  },
  {
    title: 'Users',
    route: 'user-list',
    icon: 'UsersIcon',
    permissions: ["Manage Roles", "Manage Users", "Manage Admins", "Manage Professionals"],
    children: [
      {
        title: 'Roles',
        route: 'role-list',
        permission: "Manage Roles"
      },
      {
        title: 'Users',
        route: 'user-list',
        permission: "Manage Users"
      },
      {
        title: 'Professionals',
        route: 'professional-list',
        permission: "Manage Professionals"
      },
      {
        title: 'Administrators',
        route: 'admin-list',
        permission: "Manage Admins"
      },
    ]
  },
  {
    title: 'Insights',
    route: 'home2',
    icon: 'PieChartIcon',
    permission: ["View Insights"],
    permission: "View Insights"
  },
  {
    title: 'Administration',
    route: 'home2',
    icon: 'HomeIcon',
    permissions: ["Manage Blacklist Words", "Manage Banned Users", "Manage Report Types", "Manage Reported Messages"],
    children: [
      {
        title: 'Blacklisted words',
        route: 'blacklist-words',
        permission: "Manage Blacklist Words"
      },
      {
        title: 'Banned users',
        route: 'banned-users',
        permission: "Manage Banned Users"
      },
      {
        title: 'Report types',
        route: 'report-types',
        permission: "Manage Report Types"
      },
      {
        title: 'Reported messages',
        route: 'reported-messages',
        permission: "Manage Reported Messages"
      },
    ]
  },
  {
    title: 'Learn and develop',
    route: 'learn-and-develop',
    icon: 'GridIcon',
    permissions: ["Manage Toolkits"],
    children: [
      {
        title: 'Toolkits',
        route: 'learn-and-develop',
        permission: "Manage Toolkits"
      },
      {
        title: 'Toolkit Categories',
        route: 'learn-and-develop-categories',
        permission: "Manage Toolkits"
      },
      // commented out for now
      /*
      {
        title: 'Toolkit Videos',
        route: 'learn-and-develop-videos',
        permission: "Manage Toolkits Videos"
      },*/
    ]
  },
]
