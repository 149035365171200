<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="count"
        badge-classes="bg-mhc-dark"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <!-- Header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          Notifications
        </h4>
        <b-badge
          pill
          variant="mhc-dark"
        >
          {{ count }} New
        </b-badge>
      </div>
    </li>

    <!-- Notifications -->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="scrollable-container media-list scroll-area"
      tagname="li"
    >
      <b-overlay :show="show" rounded="sm">

        <div v-for="(notification, index) in notifications" :key="notification.id">
          <b-media class="">
            <div class="d-flex justify-content-between align-items-center"  @click="removeNotification(index, notification.id)">
              <div class="col-8 cursor-pointer">
                <span>
                  <small>
                  {{ notification.data.info }}
                  </small>
                </span>
              </div>
              <div class="d-flex col-4 justify-content-between">
                <div>
                  <small>{{ notification.created_at | notificationDate }}</small>
                </div>
                <span><feather-icon
                  icon="XIcon"
                  size="18"
                  class="cursor-pointer"
                /></span>
              </div>
            </div>
          </b-media>
        </div>
      </b-overlay>
    </vue-perfect-scrollbar>

    <!-- Footer -->
    <li class="dropdown-menu-footer"><b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      block
      @click="markAllAsRead()"
    >Mark all notifications as read</b-button>
    </li>

  </b-nav-item-dropdown>
</template>

<script>
import store from '@/store/index'
import {
  BNavItemDropdown, BOverlay, BBadge, BMedia, BLink, BAvatar, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BNavItemDropdown,
    BOverlay,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    return {
      perfectScrollbarSettings,
    }
  },
  data() {
    return {
      notifications: [],
      count: 0,
      show: false,
    }
  },
  methods: {
    subscribe () {
      let pusher = store.state.app.pusher
      pusher.subscribe('private-user-' + store.state.app.userData.username)

      // New reaction added and new message reaction added!
      pusher.bind('Illuminate\\Notifications\\Events\\BroadcastNotificationCreated', data => {
        // Append new notification in case if type is not follow request
        if(data.type != 'follow-request-sent') {
          let newNotification = {
            id: data.id,
            data: {
              "core": data.core,
              "info": data.info,
              "type": data.type,
              "status": data.status
            },
            created_at: new Date()
          }
          
          // Push new notification to array
          this.notifications = [newNotification, ...this.notifications]
          this.count++
        }
      })
    },

    // Remove single notification
    removeNotification(index, id) {
      this.$http.get("/api/auth/user/notifications-mark/" + id)
      .then((res) => {
        this.count--
        this.notifications.splice(index, 1)
      }).catch((err) => {
        console.log(err)

        this.$toast({
          component: ToastificationContent,
          props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
          },
        });
      });
    },
    
    // Mark as read notification
    markAsRead(notification, index) {
      this.$http.get("/api/auth/user/notifications-mark/" + notification.id)
      .then((res) => {
        // Decrement counter and remove notification
        
      })
      .catch((err) => {
        console.log(err)
        
        this.$toast({
          component: ToastificationContent,
          props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
          },
        });
      })
    },
    // Mark all notification as read (set counter to 0 and remove all notifications)
    markAllAsRead() {
      this.$http.get("/api/auth/user/notifications-mark")
      .then((res) => {
        this.count = 0
        this.notifications = []
      })
      .catch((err) => {
        console.log(err)

        this.$toast({
          component: ToastificationContent,
          props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
          },
        });
      })
    },
  },

  created() {
    this.subscribe();
  },
  mounted() {
    if (localStorage.getItem("userData") != null) {      
      // Get all notifications
      this.$http.get("/api/auth/user/notifications")
      .then((res) => {
          this.notifications = res.data
          this.count = res.data.length
      })
      .catch((err) => {
          console.log(err)

          this.$toast({
            component: ToastificationContent,
            props: {
                title: "Something went wrong. Please try again",
                icon: "AlertCircleIcon",
                variant: "danger",
            },
          });
      })
    }

  },
  watch: {
    notifications(value) {
      this.notifications = value
    },
  },
}
</script>
